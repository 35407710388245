@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #header,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 110%;
  }
}

.whatsapp_float {
  position: fixed;
  width: 80px;
  height: 80px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 48px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 14px;
  }

  .whatsapp_float {
    width: 70px;
    height: 70px;
    bottom: 20px;
    right: 10px;
    font-size: 40px;
  }
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(fonts/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'), url(fonts/Montserrat-Bold.ttf) format('truetype');
}

